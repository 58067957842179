import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ApplicationService, Nav, Footnote } from './services/application.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  public pagetitle: string;
  public highlightColor: string;
  public logoUrl: string;
  public faviconUrl: string;
  public sidenav: Nav;
  public footerNav: Nav;
  public footnotes: Footnote[] = [];
  public sticky: boolean = false;
  public menuPosition: any;
  @ViewChild('stickyMenu') 
    public menuElement: ElementRef;
  
  constructor(private applicationService: ApplicationService) {
    
  }
  
  ngAfterViewInit(){

    this.menuPosition = this.menuElement.nativeElement.offsetTop;
  }

  ngOnInit() {

    this.applicationService.getPagetitle().subscribe((title) => {
      
      this.pagetitle = title; 
    });

    this.applicationService.getSidenav().subscribe((nav) => {
      
      this.sidenav = nav;
    });

    this.applicationService.getLogoUrl().subscribe((url) => {
 
      this.logoUrl = url;
    });

    this.applicationService.getFaviconUrl().subscribe((url) => {
 
      this.faviconUrl = url;
    });

    this.applicationService.getFooterNav().subscribe((nav) => {

      this.footerNav = nav;
    });

    this.applicationService.getFootnotes().subscribe((notes) => {

      this.footnotes = notes;
    });

    this.applicationService.getCusColor().subscribe((color) => {

      this.highlightColor = color;

      const style = document.createElement('style');
      style.innerHTML = 
        `
          h1, h2, h3 {
              color: ${this.highlightColor};
          }
          .sidenav nav a.active {
            background-color: ${this.highlightColor};
            box-shadow: 2px 2px 6px ${this.highlightColor};
          }
          .sidenav nav a {
            border-bottom: 2px solid ${this.highlightColor};
            border-right: 2px solid ${this.highlightColor};
            color: ${this.highlightColor};
          }
          .sidenav nav a:first-of-type {
            border-top: 2px solid ${this.highlightColor};
          }
          .templates.grid .template.item .bottom-box .item-label.price,
          .templates.grid .template.item .bottom-box .item-label.duration {
            color: ${this.highlightColor} !important;
          }
          .templates.grid .template.item:hover {
            border-color: ${this.highlightColor} !important;
          }
          #contract-form button[type=submit]:hover {
            color: ${this.highlightColor} !important;
          }
          #contract-form button[type=submit] {
            background-color: ${this.highlightColor} !important;
            border: 4px solid ${this.highlightColor} !important;
          }
          .postings.grid .posting.item:hover {
            border-color: ${this.highlightColor} !important;
            box-shadow: 1px 1px 2px ${this.highlightColor} !important;
            }
          .postings.grid .posting.item:hover .item-img {
            border-right: 1px solid ${this.highlightColor} !important;
          }
          .postings.grid input[type=checkbox]:checked + .posting.item .item-img {
            border-color: ${this.highlightColor} !important;
            box-shadow: 1px 0px 3px ${this.highlightColor} !important;
          }
          .postings.grid input[type=checkbox]:checked + .posting.item .item-img .checkbox-overlay {
            background-color: ${this.highlightColor} !important;
          }
          .postings.grid input[type=checkbox]:checked + .posting.item {
            border-color: ${this.highlightColor} !important;
            box-shadow: 1px 1px 4px ${this.highlightColor} !important;
          }
          .postings.grid .posting.item .bottom-box .item-label.price,
          .postings.grid .posting.item .item-description .check-icon,
          .postings.grid input[type=checkbox]:checked + .posting.item .item-label.name {
            color: ${this.highlightColor} !important;
          }
          .payment-type:checked + .payment-item {
            border-color: ${this.highlightColor} !important;
            box-shadow: 1px 1px 4px ${this.highlightColor} !important;
          }
          .payment-item i.check-icon,
          #contract-form .agb label span {
            color: ${this.highlightColor} !important;
          }
        `;
 
      document.head.appendChild(style);
    });
  }

  setFooterNavItemVisible(index: number): void {

    this.applicationService.updateFooterNavItems( (items) => {

      items[index].show = true;
    });
  }
  
  scrollTop() {
    ( function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
      }
    )();
  }

  @HostListener('window:scroll', ['$event'])
    handleScroll(){

      const windowScroll = window.pageYOffset;
      
      if(windowScroll >= this.menuPosition){
          
        this.sticky = true;

      } else {
          
        this.sticky = false;
      }
    }
}
