<div *ngIf="initialized" class="studio-detail-header">
  <h2>Dein Studio:</h2>
  <p class="studio-name">
    {{studio.Studioname}}    
    <br /><span class="studio-adress">{{studio.street}}, {{studio.zip}} {{studio.city}}</span>
  </p>
</div> 

<p *ngIf="!templates || templates.length == 0" class="text-label no-items">KEINE TEMPLATES GEFUNDEN</p>

<div *ngIf="templates && templates.length > 0" class="templates grid">
  
  <h2>Alle Verträge:</h2>

  <div *ngFor="let template of templates; index as i" [routerLink]="['/add-contract/' + studioID + '/' + template.id]" class="template item">
    <div class="item-img">
      <div *ngIf="template.productImageFilename" [style]="getContractImageUrl(template.productImageFilename)" class="item-bg"></div>
      <div *ngIf="!template.productImageFilename" class="placeholder">
        <!-- <img alt="Placeholder Image" src="assets/img/placeholder.jpg"> -->
        <img class="logo" alt="Placeholder Logo" src="{{ logoUrl }}">
        <div class="placeholder-bg"></div>     
      </div>
    </div>
    <div class="item-description">
        <p class="item-label name">{{template.cName}}<span class="footnote" *ngIf="footnotes.has(template.id)"><sup>*<sup>{{ footnotes.get(template.id).number }}</sup></sup></span></p>
        <div class="bottom-box">
          <p class="price-label"><small>{{getContractCycle(i)}}</small></p>
          <p class="item-label price"><small>Preis</small>{{template.cFee | currency:'EUR':'':'1.2-2':'de'}}€</p>
          <p class="item-label duration"><small>Laufzeit</small>{{getContractTerm(i)}}</p>  
        </div>
    </div>
  </div>

</div>

<app-footer-nav-overlay [overlayData]="legalInfo"></app-footer-nav-overlay>

