import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export class Navitem {
    
  name: string;
  icon?: string = "";
  routerlink: string;
  active: boolean = false;
  disabled: boolean = false;
  show?: boolean = true;
}

export class Nav {

  name: string;
  visible: boolean = true;
  navitems: Navitem[] = [];
}

export class Footnote {

  number: number;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private DEFAULT_LOGO: string = 'assets/img/agilea_logo.png'
  private DEFAULT_FAVI: string = 'assets/img/agilea_favicon.png'

  private isTabletMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private pagetitle: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private highlightColor: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private logoUrl: BehaviorSubject<string> = new BehaviorSubject<string>(this.DEFAULT_LOGO);
  private faviconUrl: BehaviorSubject<string> = new BehaviorSubject<string>(this.DEFAULT_FAVI);
  private sidenav: BehaviorSubject<Nav> = new BehaviorSubject<Nav>(null);
  private sidenavs: Map<String, Nav> = new Map();  
  private footnotes: BehaviorSubject<Footnote[]> = new BehaviorSubject([]);
  private footerNav: BehaviorSubject<Nav> = new BehaviorSubject<Nav>(null);
  private currSidenavName: string;
  
  constructor(private route: ActivatedRoute, private cookieService: CookieService) { 

    if ( this.cookieService.check('tabletMode') ) {

      let tabletModeOn = false;

      if ( this.cookieService.get('tabletMode').toLocaleLowerCase() == 'true' ) tabletModeOn = true;
      
      this.isTabletMode.next(tabletModeOn);
    }

    this.route.queryParamMap.subscribe( params => {

      if ( params.has('tabletMode') ) {

        let tabletModeOn = false;

        if ( params.get('tabletMode').toLocaleLowerCase() == 'true' ) {
          
          tabletModeOn = true;

          this.cookieService.set('tabletMode', 'true', null, '/', null, null, 'Lax');

        } else if ( params.get('tabletMode').toLocaleLowerCase() == 'false' ) {
        
          this.cookieService.delete('tabletMode', '/');
        }

        this.isTabletMode.next(tabletModeOn);
      }
    });

    let contractAddNav = new Nav(); 
    contractAddNav.name = "contract_add";
    contractAddNav.visible = false;
    contractAddNav.navitems = [
      {name: 'Studioauswahl', icon: 'fas fa-landmark', routerlink: '/studios', active: false, disabled: false, show: false},
      {name: 'Vertragsauswahl', icon: 'fas fa-file-signature', routerlink: '#', active: false, disabled: true, show: true},
      {name: 'Optionen und Daten', icon: 'fas fa-user-check', routerlink: '#', active: false, disabled: true, show: true}
    ];
    this.sidenavs.set(contractAddNav.name, contractAddNav);

    let footerNav = new Nav();
    footerNav.name = "footer_nav";
    footerNav.visible = false;
    footerNav.navitems = [
      {name: 'AGBs', icon: null, routerlink: null, active: false, disabled: false, show: false},
      {name: 'Impressum', icon: null, routerlink: null, active: false, disabled: false, show: false},
      {name: 'Widerruf', icon: null, routerlink: null, active: false, disabled: false, show: false},
      {name: 'Datenschutz', icon: null, routerlink: null, active: false, disabled: false, show: false},
      {name: 'SEPA-Mandat', icon: null, routerlink: null, active: false, disabled: true, show: false}
    ];
    this.footerNav.next(footerNav);

    let adminNav = new Nav(); 
    adminNav.name = "admin";
    adminNav.visible = false;
    adminNav.navitems = [
      {name: 'Syncstatus', icon: 'fas fa-landmark', routerlink: '/admin/sync-status', active: false, disabled: true, show: true},
      {name: 'Studiodaten', icon: 'fas fa-file-signature', routerlink: '/admin/studio-data', active: false, disabled: true, show: true},
      {name: 'Api Logs', icon: 'fa fa-history', routerlink: '/admin/api-logs', active: false, disabled: true, show: true}
    ];
    this.sidenavs.set(adminNav.name, adminNav);

    this.setCusColor('#265d94');
  }

  public setCusColor(highlightColor: string): void {

      this.highlightColor.next(highlightColor);
  }

  public getCusColor(): Observable<string> {
   
    return this.highlightColor.asObservable();
  }

  public setLogoUrl(url: string): void {

    if ( !url ) {

      this.logoUrl.next(this.DEFAULT_LOGO);
    }
    else {

      this.logoUrl.next(url);
    }
  }

  public getLogoUrl(): Observable<string> {
   
    return this.logoUrl.asObservable();
  }

  public setFaviconUrl(url: string): void {

    if ( !url ) {

      this.faviconUrl.next(this.DEFAULT_FAVI);
    }
    else {

      this.faviconUrl.next(url);
    }
  }

  public getFaviconUrl(): Observable<string> {
   
    return this.faviconUrl.asObservable();
  }

  public setPagetitle(title: string): void {
   
    this.pagetitle.next(title);
  }

  public getPagetitle(): Observable<string> {
   
    return this.pagetitle.asObservable();
  }

  public setSidenav(sidenavName: string): void {
    
    const sidenav = this.sidenavs.get(sidenavName);
    
    if ( sidenav ) {

      this.currSidenavName = sidenavName;

      this.setSidenavVisibility(true);
      this.resetSidenavItemActive();

      this.sidenav.next(sidenav);
    }
  }

  public setSidenavVisibility(visiblity: boolean): void {
    
    if ( this.currSidenavName ) {

      let sidenav = this.sidenavs.get(this.currSidenavName);
      
      sidenav.visible = visiblity;
      this.sidenav.next(sidenav);  
    }
  }

  public updateSidenavItems(itemFunction: Function): void {

    if ( this.currSidenavName ) {

      let sidenav = this.sidenavs.get(this.currSidenavName);
      
      if ( sidenav.navitems ) {

        itemFunction(sidenav.navitems);
      }
      this.sidenav.next(sidenav);  
    }
  }

  public resetSidenavItemActive() {

    if ( this.currSidenavName ) {

      let sidenav = this.sidenavs.get(this.currSidenavName);
      
      sidenav.navitems.forEach((navitem) => {

        navitem.active = false;
      });
    }
      
  }

  public getSidenav(): Observable<Nav> {
   
    return this.sidenav.asObservable();
  }


  public setFooterNavVisibility(visiblity: boolean): void {
    
    if ( this.footerNav ) {

      let nav = this.footerNav.getValue();
      nav.visible = visiblity;

      this.footerNav.next(nav);  
    }
  }

  public updateFooterNavItems(itemFunction: Function): void {

    if ( this.footerNav ) {

      let nav = this.footerNav.getValue();

      if ( nav.navitems ) {

        itemFunction(nav.navitems);
      }
      this.footerNav.next(nav);  
    }
  }

  public getFooterNav(): Observable<Nav> {
   
    return this.footerNav.asObservable();
  }

  setFootnotes(notes: Footnote[]) {

    this.footnotes.next(notes);
  }

  getFootnotes(): Observable<Footnote[]> {

    return this.footnotes.asObservable();
  }

  isTabletModeOn(): Observable<boolean> {

    return this.isTabletMode.asObservable();
  }
}
