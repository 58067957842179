/*
// config dev
let config = {
    imageBaseUrl: "https://webvertrag-test.agilea.de/images/products/",
    apiBasePath: "http://localhost:3000/api"
}
export const Config = config;
*/

// config stage
let config = {
    imageBaseUrl: "https://aktiv-unna.agilea.de/images/products/",
    apiBasePath: "https://api.aktiv-unna.membr.de/api"
}
export const Config = config;

// config prod
/*
let config = {
    imageBaseUrl: "",
    apiBasePath: ""
}
export const Config = config;
*/