<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="medium" color="#000000" type="ball-circus" [fullScreen]="true">
  <p style="color: black; font-size: 16px;">Buchung wird bearbeitet...</p>
</ngx-spinner>

<div *ngIf="initialized" class="contract-detail-header">
  <h2>Dein Vertrag:</h2>
  <p class="template-name">
    {{template.cName}}<span class="footnote" *ngIf="hasFootnote"><sup>*<sup>1</sup></sup></span>
    <br /><span class="template-price">Preis: {{template.cFee | currency:'EUR':'':'1.2-2':'de'}}€, Laufzeit: {{getContractTerm()}}</span>
  </p>
</div> 

<form #contractForm="ngForm" id="contract-form" *ngIf="initialized && !submitted" (ngSubmit)="onSubmit()"> 

  <div *ngIf="postings && postings.length > 0" class="postings grid">
    <h2>Zusatzoptionen:</h2>
    
    <ng-container *ngFor="let posting of postings; index as i">
      <ng-container *ngIf="posting.canVary">
        <input type="checkbox" class="hidden" (change)="calcSums()" [(ngModel)]="selectedPostingsIndizes[i]" [id]="'posting' + i" [name]="'posting' + i" [checked]="posting.defaultValue" />
        <div class="posting item">
          <label [for]="'posting' + i"></label>
          <div class="item-img">
            <div class="checkbox-overlay"></div>
            <div class="placeholder">
              <!-- <img alt="Placeholder Image" src="assets/img/placeholder.jpg"> -->
              <img class="logo" alt="Placeholder Logo" src="{{ logoURL }}">
              <!-- <div class="placeholder-bg"></div>  --> 
            </div>
          </div>
          <div class="item-description">
              <p class="item-label name">{{posting.name}}</p>
              <div class="bottom-box">
                <p class="price-label"><small>{{getPostingCycle(i)}}</small></p>
                <p class="item-label price"><small>Preis</small>{{posting.fee | currency:'EUR':'':'1.2-2':'de'}}€</p>
                <!-- <p class="item-label duration"><small>Laufzeit</small>???</p> -->
              </div>
              <i class="check-icon fas fa-check-circle"></i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div> 
  <div class="inclusive">
    <ng-container *ngFor="let posting of postings; index as i">
      <ng-container *ngIf="!posting.canVary">
        <div class="posting item">
          <i class="far fa-check-circle"></i>
          <strong>{{ posting.name }} </strong><span *ngIf="posting.fee && posting.fee > 0"> {{ posting.fee | currency:'EUR':'':'1.2-2':'de' }}€ </span><span *ngIf="getPostingCycle(i) != '-'"> ({{ getPostingCycle(i) }}) </span> 
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let service of services">
      <div class="service item">
        <i class="far fa-check-circle"></i>
        <strong>{{ service }} </strong><span> inkl. </span> 
      </div>
    </ng-container>
  </div>

  <h2>Deine Daten:</h2>
  <div id="contract-member-data">

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
          <!-- <label for="firstname">Vorname</label> -->
          <input type="text" class="form-control" id="firstname" [(ngModel)]="newContract.firstName" #firstname="ngModel" name="firstname" 
            placeholder="Vorname" required pattern="^[a-zA-Z ,-\.&'äöü/]*$">
            <div *ngIf="firstname.invalid && firstname.errors && (firstname.dirty || firstname.touched || showErrors)" class="alert alert-danger">
              Bitte gib einen gültigen Namen an.
            </div>
        </div>
      </div>
      <div class="col-md-6">
          <div class="form-group">
            <!-- <label for="lastname">Name</label> -->
            <input type="text" class="form-control" id="lastname" [(ngModel)]="newContract.lastName" #lastname="ngModel" name="lastname" 
              placeholder="Nachname" required pattern="^[a-zA-Z ,-\.&'äöü/]*$">
              <div *ngIf="lastname.invalid  && lastname.errors  && (lastname.dirty  || lastname.touched || showErrors)" class="alert alert-danger">
                Bitte gib einen gültigen Namen an.
              </div>
          </div>
      </div>
    </div>

    <div class="form-row">
      <!-- <div class="col-md-6">
        &nbsp;
      </div> -->
      <div class="col-md-3 col-6">
        <div class="form-group">
            <label for="birthday">Geburtstag</label>
            <div class="input-group">         
              <input class="form-control" placeholder="dd.mm.yyyy" id="birthday" name="birthday" [(ngModel)]="birthday"
                ngbDatepicker [footerTemplate]="footerTemplate" #d="ngbDatepicker" required [minDate]="earliestBirthday" [maxDate]="today" #bd="ngModel">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary far fa-calendar-alt" (click)="d.toggle()" type="button"></button>
              </div>
              <div *ngIf="bd.invalid && bd.errors && (bd.dirty || bd.touched || showErrors)" class="alert alert-danger">
                  Bitte wähle ein gültiges Datum aus.
              </div>
            </div>
        </div>
        <ng-template #footerTemplate>
            <hr class="my-0">
            <!-- <button class="btn btn-primary btn-sm m-2 float-left" (click)="birthday = today; d.close()">Heute</button> -->
            <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Schließen</button>
        </ng-template>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
            <label for="gender">Geschlecht</label>
            <select class="form-control" id="gender" [(ngModel)]="newContract.gender" #gender="ngModel" name="gender" required pattern="^[MWD]{1,1}$">
              <option selected disabled value="" style="display:none;">auswählen..</option>
              <option value="W">Weiblich</option>
              <option value="M">Männlich</option>
              <option value="D">Divers</option>
            </select>
            <div *ngIf="gender.invalid  && gender.errors  && (gender.dirty  || gender.touched || showErrors)" class="alert alert-danger">
              Bitte auswählen.
            </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-8">
        <div class="form-group">
            <!-- <label for="email">E-Mail</label> -->
            <input type="email" class="form-control" id="email" [(ngModel)]="newContract.email" #email="ngModel" name="email" 
              placeholder="E-Mail" [required]="!isTabletModeOn" email>
            <div *ngIf="email.invalid && email.errors && (email.dirty || email.touched || showErrors)" class="alert alert-danger">
                Bitte gib eine gültige E-Mail-Adresse an.
            </div>
        </div>
      </div>
      <div class="col-md-4">  
        <div class="form-group">
            <!-- <label for="phone">Tel.</label> -->
            <input type="tel" class="form-control" id="phone" [(ngModel)]="newContract.phone1" #phone="ngModel" name="phone" 
              placeholder="Telefon" required pattern="^[0-9 -/+]*$">
            <div *ngIf="phone.invalid  && phone.errors  && (phone.dirty  || phone.touched || showErrors)" class="alert alert-danger">
              Bitte gib eine gültige Telefonnummer an.
            </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6">
        <div class="form-group">
            <!-- <label for="street">Straße</label> -->
            <input type="text" class="form-control" id="street" [(ngModel)]="newContract.street" #street="ngModel" name="street" 
              placeholder="Straße" required pattern="^[a-zA-Z0-9 ,-\.&'ßäöü/]*$">
            <div *ngIf="street.invalid  && street.errors  && (street.dirty  || street.touched || showErrors)" class="alert alert-danger">
              Bitte gib eine gültige Straße an.
            </div>
        </div>
      </div>
      <div class="col-md-2">
          <div class="form-group">
              <!-- <label for="zip">PLZ</label> -->
              <input type="text" class="form-control" id="zip" [(ngModel)]="newContract.zip" #plz="ngModel" name="zip" 
                placeholder="Plz" required pattern="^[0-9]{5,5}$">
              <div *ngIf="plz.invalid  && plz.errors  && (plz.dirty  || plz.touched || showErrors)" class="alert alert-danger">
                Bitte gib eine gültige PLZ an.
              </div>
          </div>
        </div>  
      <div class="col-md-4">
        <div class="form-group">
            <!-- <label for="city">Stadt</label> -->
            <input type="text" class="form-control" id="city" [(ngModel)]="newContract.city" #city="ngModel" name="city" 
              placeholder="Ort" required pattern="^[a-zA-Z ,-\.&'äöü/]*$">
            <div *ngIf="city.invalid  && city.errors  && (city.dirty  || city.touched || showErrors)" class="alert alert-danger">
              Bitte gib eine gültige Stadt an.
            </div>
        </div>
      </div>
    </div>
  

    <h3>Vertragsdaten</h3>

    <div class="form-row">
      <!-- <div class="col-md-6">
        &nbsp;
      </div> -->
      <div class="col-md-3 col-6">
        <div class="form-group">
            <label for="contractbegin">Vertragsbeginn</label>
            <div class="input-group">         
              <input class="form-control" placeholder="dd.mm.yyyy" id="conractbegin" name="conractbegin" [(ngModel)]="contractbegin" 
                ngbDatepicker [footerTemplate]="footerTemplate2" #d2="ngbDatepicker"required [minDate]="today" [markDisabled]="disabledDates" #cb="ngModel">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary far fa-calendar-alt" (click)="d2.toggle()" type="button"></button>
              </div>
              <div *ngIf="cb.invalid && cb.errors && (cb.dirty || cb.touched || showErrors)" class="alert alert-danger">
                  Bitte wähle ein gültiges Datum aus.
              </div>
            </div>
        </div>
        <ng-template #footerTemplate2>
            <hr class="my-0">
            <!-- <button class="btn btn-primary btn-sm m-2 float-left" (click)="contractbegin = today; d2.close()">Heute</button> -->
            <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d2.close()">Schließen</button>
        </ng-template>
      </div>
      <div class="col-md-3 col-6">
        <div class="form-group">
            <label for="accessbegin">Vorabnutzung ab</label>
            <div class="input-group">         
              <input class="form-control" placeholder="dd.mm.yyyy" id="accessbegin" [minDate]="getEarliestAccess()" [maxDate]="contractbegin"
                name="accessbegin" [(ngModel)]="accessbegin" ngbDatepicker [footerTemplate]="footerTemplate3" #d3="ngbDatepicker" #ab="ngModel">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary far fa-calendar-alt" (click)="d3.toggle()" type="button"></button>
              </div>
              <div *ngIf="ab.invalid && ab.errors && (ab.dirty || ab.touched || showErrors)" class="alert alert-danger">
                  Bitte wähle ein gültiges Datum aus.
              </div>
            </div>
        </div>
        <ng-template #footerTemplate3>
            <hr class="my-0">
            <button class="btn btn-primary btn-sm m-2 float-left" (click)="accessbegin = today; d3.close()">Heute</button>
            <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d3.close()">Schließen</button>
        </ng-template>
      </div>
    </div>

  </div>
  
  
  <div class="payment-container">
    <h3>Zahlungsart</h3>

    <div class="row row-eq-height payment-types">
      <div class="col-md-6">
        <input type="radio" id="sepa" name="payment" value="sepa" class="payment-type" [(ngModel)]="payment" required /> 
        <label for="sepa" class="payment-item">
          <i class="fas fa-credit-card"></i><i class="fas fa-signature"></i><i class="fas fa-pencil-alt"></i>
          <h2>Zahlen per Lastschrift</h2>
          <p>Bezahle bequem per Lastschrifteinzug von deinem Konto.</p>
          <i class="check-icon far fa-check-circle"></i>
        </label>
      </div>
      <div class="col-md-6">
        <input type="radio" id="concardis" name="payment" value="concardis" disabled class="payment-type" [(ngModel)]="payment" required /> 
        <label for="concardis" class="payment-item disabled">
          <div class="disabled-bg"></div>
          <i class="fab fa-paypal"></i><i class="fab fa-cc-visa"></i><i class="fab fa-cc-mastercard"></i>
          <h2>PayPal, Kreditkarte, etc.</h2>
          <p>Bezahle über unseren Zahlungsdienstleister Concardis mit dem Zahlungsmittel deiner Wahl unter vielen Optionen.</p>
          <p><strong>(In Kürze verfügbar...)</strong></p>
        </label>
      </div>
    </div>

    <div class="sepa-container" *ngIf="payment == 'sepa'">
      <label>Bankdaten</label>

      <div class="form-row">
          <div class="col-md-8">
            <div class="form-group">
                <!-- <label for="email">E-Mail</label> -->
                <input type="text" class="form-control" id="iban" [(ngModel)]="iban" name="iban" 
                  placeholder="IBAN" required #ibannum="ngModel">
                <div *ngIf="ibannum.invalid && ibannum.errors && (ibannum.dirty || ibannum.touched || showErrors)" class="alert alert-danger">
                  Bitte gib eine gültige IBAN an.
                </div>
            </div>
          </div>
          <div class="col-md-4">  
            <div class="form-group">
                <!-- <label for="phone">Tel.</label> -->
                <input type="text" class="form-control" id="bic" [(ngModel)]="bic" name="bic" 
                  placeholder="BIC" required pattern="^([A-Za-z0-9]{8})|([A-Za-z0-9]{11})$" #bicnum="ngModel">
                <div *ngIf="bicnum.invalid && bicnum.errors && (bicnum.dirty || bicnum.touched || showErrors)" class="alert alert-danger">
                  Bitte gib eine gültige BIC an.
                </div>
            </div>
          </div>
        </div>
        <div class="form-group sepa">
          <input type="checkbox" class="form-control" id="sepaAccepted" [(ngModel)]="sepaAccepted" name="sepaAccepted" #sepa="ngModel" required>
          <label for="sepaAccepted">Hiermit stimme ich der Erteilung des <span (click)="openSepa($event)">SEPA-Mandats</span> zum Lastschrifteinzug zu.</label>
          <div *ngIf="sepa.invalid && sepa.errors && (sepa.dirty || sepa.touched || showErrors)" class="alert alert-danger block">
            Bitte stimme der Erteilung des SEPA-Mandats zum Lastschrifteinzug zu!
          </div>
        </div>
    </div>
  </div>

  <div *ngIf="paymentError" class="payment-error">
    Leider konnte die Bankverbindung nicht validiert werden, bitte überprüfe deine Angaben oder nutze eine andere Bankverbindung.
  </div>

  <div id="order-overview">
    <h2>Zusammenfassung:</h2>
    <div class="order-overview my-table">
      <ul class="table-head c5">
        <li class="w2">Posten</li><li>Kosten</li><li>Zahlungs&shy;zyklus</li><li>Kosten / Monat</li>
      </ul>
      <ul class="table-row c5">
        <li class="w2">{{template.cName}}</li>
        <li>{{template.cFee | currency:'EUR':'':'1.2-2':'de'}}€ <small><i>(inkl. {{template.cFeeTaxRate}}% MwSt.)</i></small></li>
        <li>{{getContractCycle()}}</li>
        <li *ngIf="getMonthlyCosts()">{{getMonthlyCosts() | currency:'EUR':'':'1.2-2':'de'}}€</li>
        <li *ngIf="!getMonthlyCosts()">-</li>
      </ul>
      <ng-container *ngFor="let posting of postings; index as i">
        <ul *ngIf="selectedPostingsIndizes[i]" class="table-row c5">
            <li class="w2">{{posting.name}}</li>
            <li>{{posting.fee | currency:'EUR':'':'1.2-2':'de'}}€ <small><i>(inkl. {{posting.vatRate}}% MwSt.)</i></small></li>
            <li>{{getPostingCycle(i)}}</li>
            <li *ngIf="getMonthlyCosts(i)">{{getMonthlyCosts(i) | currency:'EUR':'':'1.2-2':'de'}}€</li>
            <li *ngIf="!getMonthlyCosts(i)">-</li>
        </ul>
      </ng-container>
      <div class="sums">
        <ul class="table-head sums">
          <li>SUMMEN</li>
        </ul>
        <ul class="table-row c5">
          <li></li><li></li><li></li>
          <li><strong>einmalig:</strong></li>
          <li>{{totalPriceOnce | currency:'EUR':'':'1.2-2':'de'}}€</li>
        </ul>
        <ul class="table-row c5">
          <li></li><li></li><li></li>
          <li><strong>monatlich:</strong></li>
          <li>{{totalPriceMonthly | currency:'EUR':'':'1.2-2':'de'}}€</li>
        </ul>
      </div>
    </div>  
  </div>

  <div *ngIf="hasAgb" class="form-group agb">
    <input type="checkbox" class="form-control" id="agbAccepted" [(ngModel)]="agbAccepted" name="agbAccepted" #agb="ngModel" required>
    <label for="agbAccepted">Hiermit akzeptiere ich die <span (click)="openAgb($event)">allgemeinen Geschäftsbedingungen</span>.</label>
    <div *ngIf="agb.invalid && agb.errors && (agb.dirty || agb.touched || showErrors)" class="alert alert-danger block">
      Bitte stimme den allgemeinen Geschäftsbedingungen zu!
    </div>
  </div>

  <div class="signature" *ngIf="isTabletModeOn">
    <h3>Unterschrift:</h3>
    <div class="signature-pad">
      <app-signature-pad (signaturePng)="onSignatureChange($event)"></app-signature-pad>
    </div>
    <div *ngIf="sigError" class="sig-error sync-error">
      <p>Bitte speichere zunächst eine Unterschrift ab.</p>
    </div>
  </div>

  <div *ngIf="contractForm.invalid" class="error-hint" (click)="setShowErrors(true)">
    <button type="submit" [disabled]="true" class="btn btn-success disabled">Kostenpflichtig Buchen</button> <!-- -->
  </div>
  <button *ngIf="!contractForm.invalid" type="submit" class="btn btn-success">Kostenpflichtig Buchen</button> <!-- -->
  
</form>

<div *ngIf="syncError" class="sync-error">
  <p>Um das Angebot stets auf dem aktuellen Stand zu halten, wurden die Vertragsdaten im Hintergrund aktualisiert. Eventuell ist davon auch dein Vertrag betroffen. Bitte überprüfe oben in der Zusammenfassung, ob die Konditionen für dich noch passen.</p>
  <button (click)="discardSyncError()">OK</button>
</div>


<div *ngIf="submitted">

  <div *ngIf="success">
    <h2 style="text-transform: uppercase;">Vielen Dank für deine Buchung!</h2>
    <p *ngIf="!isTabletModeOn" class="big">
      <strong>Du erhälst alle relevanten Dokumente nochmals per Mail.<br /> 
      <span>Um den Vertrag freizuschalten, öffne bitte den entsprechenden Link in der E-Mail Nachricht mit der Bestätigung.</span></strong>
    </p>
    <div *ngIf="isTabletModeOn">
      <strong>Hier deine Dokumente zum Download im PDF Format:</strong> <br />
      <a [href]="downloadUrl + docLink" download>Vertragsdokument</a> <br />
      <a [href]="downloadUrl + sepaLink" download>SEPA Lastschriftmandat</a> <br />
    </div>
    <div id="order-overview">
      <h2>Zusammenfassung:</h2>
      <p style="margin-bottom: 0;">
        <strong style="min-width: 100px; display: inline-block;">Vertragsbegin:</strong> {{ newContract.contractBegin   | date:'dd.MM.yyyy' }}
      </p>
      <p>
        <strong style="min-width: 100px; display: inline-block;">Nutzungsbegin:</strong> {{ newContract.accessBeginDate | date:'dd.MM.yyyy' }}
      </p>
      <div class="order-overview my-table">
        <ul class="table-head c5">
          <li class="w2">Posten</li><li>Kosten</li><li>Zahlungs&shy;zyklus</li><li>Kosten / Monat</li>
        </ul>
        <ul class="table-row c5">
          <li class="w2">{{template.cName}}</li>
          <li>{{template.cFee | currency:'EUR':'':'1.2-2':'de'}}€ <small><i>(inkl. {{template.cFeeTaxRate}}% MwSt.)</i></small></li>
          <li>{{getContractCycle()}}</li>
          <li *ngIf="getMonthlyCosts()">{{getMonthlyCosts() | currency:'EUR':'':'1.2-2':'de'}}€</li>
          <li *ngIf="!getMonthlyCosts()">-</li>
        </ul>
        <ng-container *ngFor="let posting of postings; index as i">
          <ul *ngIf="selectedPostingsIndizes[i]" class="table-row c5">
              <li class="w2">{{posting.name}}</li>
              <li>{{posting.fee | currency:'EUR':'':'1.2-2':'de'}}€ <small><i>(inkl. {{posting.vatRate}}% MwSt.)</i></small></li>
              <li>{{getPostingCycle(i)}}</li>
              <li *ngIf="getMonthlyCosts(i)">{{getMonthlyCosts(i) | currency:'EUR':'':'1.2-2':'de'}}€</li>
              <li *ngIf="!getMonthlyCosts(i)">-</li>
          </ul>
        </ng-container>
        <div class="sums">
          <ul class="table-head sums">
            <li>SUMMEN</li>
          </ul>
          <ul class="table-row c5">
            <li></li><li></li><li></li>
            <li><strong>einmalig:</strong></li>
            <li>{{totalPriceOnce | currency:'EUR':'':'1.2-2':'de'}}€</li>
          </ul>
          <ul class="table-row c5">
            <li></li><li></li><li></li>
            <li><strong>monatlich:</strong></li>
            <li>{{totalPriceMonthly | currency:'EUR':'':'1.2-2':'de'}}€</li>
          </ul>
        </div>
      </div>  
      <h2>Initiale Abbuchung:</h2>
      <div class="order-overview my-table">
        <ul class="table-head c5">
          <li class="w2">Betreff</li><li></li><li>Ab&shy;buchungs&shy;betrag</li><li>Fällig&shy;keit</li>
        </ul>
        <ng-container *ngFor="let initialPosting of result; index as i">
          <ul class="table-row c5">
              <li class="w2">{{initialPosting.transferReason}}</li>
              <li></li>
              <li>{{initialPosting.outstandingAmount | currency:'EUR':'':'1.2-2':'de'}}€</li>
              <li>{{initialPosting.expiryDate | date:'dd.MM.yyyy'}}</li>
          </ul>
        </ng-container>
      </div> 
      <ng-container *ngIf="isTabletModeOn">
        <p>&nbsp;</p>
        <h2>Unterschrift:</h2>
        <img [src]="newContract.signature" />
      </ng-container>
    </div>
    <ng-container *ngIf="isTabletModeOn">
      <p>&nbsp;</p>
      <p>
        <button [routerLink]="'/studio/' + studioID" class="btn btn-success">ZURÜCK ZUM START</button>
      </p>
    </ng-container>
  </div>

  <div *ngIf="!success">
    <h2>Leider ist ein unerwarteter Fehler aufgetreten!</h2>
    <p>Bitte versuche es später erneut oder wende dich an dein Studio falls der Fehler wieder auftritt.</p>
  </div>

</div>


<app-footer-nav-overlay [overlayData]="legalInfo"></app-footer-nav-overlay>

<!--  DEBUG (!)
<code style="font-size: 18px; border: 1px solid; border-radius: 4px; background: #f2f2f2; padding: 20px; display: block; margin-top: 20px;">
  <strong>TESTAUSAGBE - neuer Vertrag:</strong>
  <pre>{{ getDiagnostics() }}</pre>
</code>
 -->